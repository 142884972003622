import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1295 903">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">


<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M917.000000,904.000000 
	C611.367981,904.000000 306.235962,904.000000 1.051976,904.000000 
	C1.051976,603.068848 1.051976,302.137665 1.051976,1.103243 
	C432.548798,1.103243 864.097656,1.103243 1295.823242,1.103243 
	C1295.823242,301.999908 1295.823242,602.999939 1295.823242,904.000000 
	C1169.804077,904.000000 1043.652100,904.000000 917.000000,904.000000 
M1014.971985,548.500000 
	C1014.971985,445.008728 1014.967529,341.517456 1014.978149,238.026169 
	C1014.979370,226.166733 1012.536804,214.940933 1006.322449,204.731842 
	C999.534546,193.580566 989.753052,187.066284 976.492065,187.062943 
	C754.676758,187.006989 532.861389,186.988373 311.046326,187.230591 
	C305.558777,187.236572 299.350555,189.186111 294.724182,192.164307 
	C280.687866,201.200012 274.127747,215.136520 274.108002,231.440216 
	C273.919495,387.093994 273.958618,542.748169 274.113464,698.401978 
	C274.119110,704.087830 275.249786,710.030457 277.129669,715.408264 
	C282.804688,731.642883 295.305542,744.299622 317.032288,744.254883 
	C534.846985,743.806030 752.663025,743.981995 970.478638,743.982239 
	C972.978455,743.982239 975.490479,744.147156 977.975891,743.958801 
	C988.445984,743.165344 996.923035,738.787048 1003.439941,730.337585 
	C1011.865112,719.413879 1014.861572,706.826660 1014.907349,693.488037 
	C1015.071899,645.492554 1014.971985,597.496094 1014.971985,548.500000 
z"/>
<path fill="#DEDEDC" opacity="1.000000" stroke="none" 
	d="
M1014.971985,549.000000 
	C1014.971985,597.496094 1015.071899,645.492554 1014.907349,693.488037 
	C1014.861572,706.826660 1011.865112,719.413879 1003.439941,730.337585 
	C996.923035,738.787048 988.445984,743.165344 977.975891,743.958801 
	C975.490479,744.147156 972.978455,743.982239 970.478638,743.982239 
	C752.663025,743.981995 534.846985,743.806030 317.032288,744.254883 
	C295.305542,744.299622 282.804688,731.642883 277.129669,715.408264 
	C275.249786,710.030457 274.119110,704.087830 274.113464,698.401978 
	C273.958618,542.748169 273.919495,387.093994 274.108002,231.440216 
	C274.127747,215.136520 280.687866,201.200012 294.724182,192.164307 
	C299.350555,189.186111 305.558777,187.236572 311.046326,187.230591 
	C532.861389,186.988373 754.676758,187.006989 976.492065,187.062943 
	C989.753052,187.066284 999.534546,193.580566 1006.322449,204.731842 
	C1012.536804,214.940933 1014.979370,226.166733 1014.978149,238.026169 
	C1014.967529,341.517456 1014.971985,445.008728 1014.971985,549.000000 
M601.117859,331.571350 
	C601.117859,331.571350 601.181519,331.161987 601.068115,331.034882 
	C601.068115,331.034882 601.138306,331.190094 601.707458,331.154144 
	C602.075317,331.057495 602.443115,330.960876 603.558289,330.936188 
	C605.061462,329.980896 606.564636,329.025604 608.853088,328.010223 
	C610.851379,327.932068 613.179932,328.340179 613.399780,324.953552 
	C613.399780,324.953552 613.774353,324.850311 614.592651,324.879059 
	C621.068176,323.180603 627.518066,321.374786 634.028076,319.820862 
	C638.471924,318.760132 642.968262,317.587463 647.496399,317.281189 
	C657.023560,316.636780 666.585815,316.353455 676.136230,316.286163 
	C680.088196,316.258270 684.046753,317.165283 688.002380,317.647125 
	C688.127869,316.812042 688.253418,315.976929 688.378906,315.141846 
	C685.188293,314.474854 681.997742,313.807892 678.407654,313.092865 
	C678.407654,313.092865 678.028442,312.954865 677.879944,312.195526 
	C677.042969,311.499939 676.272034,310.307404 675.358704,310.185974 
	C666.691040,309.033203 658.003296,308.025665 649.312317,307.057373 
	C645.876892,306.674652 642.399170,306.125641 638.975525,306.307709 
	C633.381897,306.605194 627.757080,307.121948 622.259888,308.155273 
	C618.379822,308.884583 614.689270,310.621704 610.123352,311.975037 
	C608.380310,312.612518 606.637329,313.250000 604.187744,313.967957 
	C603.066406,314.579041 601.945007,315.190125 600.081482,315.921997 
	C598.699890,316.596283 597.318237,317.270569 595.532837,318.176056 
	C595.272339,318.362579 595.083435,318.604889 594.664368,319.087799 
	C594.664368,319.087799 594.321716,319.176117 593.759705,319.215393 
	C593.503662,319.475952 593.247559,319.736542 592.845947,320.388855 
	C592.845947,320.388855 592.778564,320.801300 592.897522,320.926208 
	C592.897522,320.926208 592.832153,320.766602 592.264221,320.809296 
	C591.896484,320.904327 591.528748,320.999390 590.654724,321.174316 
	C590.431946,321.421478 590.209106,321.668640 589.630432,322.346252 
	C589.133972,322.590118 588.637451,322.833984 587.505371,323.068329 
	C586.969055,323.625702 586.432800,324.183075 585.961243,324.898773 
	C585.961243,324.898773 586.021606,325.058807 585.574402,324.990021 
	C585.574402,324.990021 585.128052,325.064514 584.630493,325.177673 
	C584.369873,325.392853 584.109253,325.608032 583.133057,325.945465 
	C577.395813,328.239197 573.221558,332.132324 570.485413,338.282349 
	C570.297180,338.786560 570.108948,339.290771 569.765808,340.306549 
	C569.738525,340.482635 569.711304,340.658722 569.308105,341.055542 
	C569.079102,341.254120 568.936279,341.502289 568.451599,341.877136 
	C568.451599,341.877136 568.041687,342.022583 567.553467,342.023468 
	C567.435120,342.378387 567.316772,342.733307 567.001709,343.647522 
	C566.910828,344.029175 566.819946,344.410858 566.937744,344.877167 
	C566.937744,344.877167 566.755798,344.744568 566.337891,344.817749 
	C566.337891,344.817749 565.948792,344.987030 565.513916,345.175323 
	C565.251404,345.363220 565.047546,345.601349 564.226379,346.062256 
	C561.154114,351.556183 557.776672,356.904938 555.078674,362.576904 
	C550.646973,371.893463 548.950256,382.040344 548.162659,392.174988 
	C546.750061,410.352570 550.582336,427.749847 558.410645,444.122467 
	C560.349976,448.178467 564.140930,451.349121 567.004883,454.997559 
	C567.004883,454.997559 567.077209,454.928192 567.024719,455.690491 
	C568.359985,457.117615 569.695251,458.544708 571.000000,460.000000 
	C571.000000,460.000000 571.028625,459.969849 570.999817,460.708832 
	C572.766296,462.357513 574.532837,464.006195 576.181641,465.819763 
	C576.181641,465.819763 576.345337,465.700439 576.160522,466.489838 
	C576.877563,469.549255 582.524719,474.255005 585.213745,473.862244 
	C589.004822,473.308472 587.727844,470.249756 587.998779,468.001221 
	C587.998779,468.001221 587.950745,467.963165 588.674194,467.909058 
	C590.290894,464.190979 591.907532,460.472931 593.564941,456.643738 
	C593.564941,456.643738 593.605591,456.532593 594.336914,456.725220 
	C596.314392,453.260895 598.560791,449.919403 600.211182,446.305695 
	C603.281799,439.582092 606.005249,432.699951 608.983337,425.987335 
	C608.983337,425.987335 608.852844,425.911041 609.501343,425.685516 
	C611.351379,422.868958 613.201416,420.052429 615.699951,417.117157 
	C616.136353,417.153076 616.572754,417.188995 617.661560,417.726349 
	C625.119934,417.539490 632.578247,417.352661 640.124084,417.058136 
	C640.124084,417.058136 640.244019,417.127777 640.899597,417.101593 
	C641.336853,417.073212 641.774109,417.044830 642.396118,417.936401 
	C642.396118,424.189880 642.396118,430.443359 642.396118,436.389923 
	C665.241394,436.389923 687.429749,436.389923 711.313843,436.389923 
	C705.044067,445.445709 700.069397,454.161774 693.639282,461.623535 
	C676.217773,481.839874 652.886719,488.236145 627.213440,487.752960 
	C617.828369,487.576294 608.464539,486.272095 599.091003,485.482300 
	C628.975647,496.886200 659.168030,503.911194 689.360657,487.182373 
	C715.597595,472.645264 727.919678,447.626587 737.978394,419.711243 
	C711.968689,419.711243 687.073608,419.711243 661.865601,419.711243 
	C661.865601,417.147736 661.839355,415.342010 661.869690,413.537201 
	C662.199646,393.877594 662.574402,374.218506 662.797363,354.557709 
	C662.812927,353.179626 661.721924,351.789001 660.812256,349.671356 
	C658.985352,348.796844 657.221191,347.370911 655.319275,347.154358 
	C650.231995,346.575134 645.065796,346.115936 639.968628,346.369019 
	C635.560608,346.587921 630.860046,346.579926 628.882324,352.459778 
	C627.435913,356.759857 624.243408,360.440063 622.356628,364.633850 
	C620.565552,368.615051 619.459961,372.904694 618.000488,377.001221 
	C618.000488,377.001221 618.064941,377.044922 617.373657,377.112640 
	C615.537048,380.121857 613.626587,383.089081 611.884399,386.152008 
	C610.081421,389.321899 608.247620,392.498749 606.774658,395.826324 
	C604.402100,401.186371 602.314697,406.672668 600.027100,411.988647 
	C600.027100,411.988647 600.123535,412.093445 599.389404,412.152740 
	C598.612976,414.121002 597.836487,416.089264 597.005310,417.996735 
	C597.005310,417.996735 597.067810,418.052002 596.369690,418.121857 
	C592.462585,424.671051 588.555481,431.220276 583.904846,439.015808 
	C582.068176,435.859772 580.341064,433.283997 578.999268,430.521118 
	C571.736816,415.566864 568.598389,399.733734 570.130188,383.201172 
	C570.620911,377.904327 572.537842,372.685638 574.220032,367.568573 
	C575.852478,362.602783 578.021057,357.813293 580.000000,353.000000 
	C580.000000,353.000000 579.942627,352.958923 580.649170,352.923523 
	C582.436462,350.291382 584.223755,347.659210 585.998291,344.997101 
	C585.998291,344.997101 586.029663,345.006653 586.502319,344.801422 
	C586.652649,344.502655 586.802979,344.203918 587.122742,343.474213 
	C587.304016,343.208771 587.547485,343.026215 588.368713,342.768005 
	C588.545166,342.467468 588.721680,342.166901 588.954285,342.009491 
	C588.954285,342.009491 588.877258,341.875366 589.644470,341.934845 
	C590.750854,340.608124 591.857239,339.281372 592.999817,338.000641 
	C592.999817,338.000641 592.953674,337.963959 593.718140,337.995392 
	C595.838745,336.353241 597.959412,334.711090 600.486450,332.793182 
	C600.753601,332.577118 600.918274,332.300140 601.117859,331.571350 
M457.220581,510.079559 
	C457.464600,510.046814 457.708649,510.014038 458.514404,509.884796 
	C458.997833,509.590363 459.481293,509.295898 460.606354,508.963470 
	C460.924988,508.501068 461.243591,508.038635 461.649841,507.559418 
	C461.649841,507.559418 461.663635,507.471222 462.342590,507.748474 
	C463.142303,507.055908 463.942017,506.363342 464.751556,505.734070 
	C464.751556,505.734070 464.806091,505.700470 465.751831,505.885956 
	C469.865112,505.893646 473.978363,505.901306 477.988892,505.921356 
	C477.988892,505.921356 478.084015,505.879395 478.133728,506.615875 
	C479.164734,506.979095 480.195770,507.342346 481.216003,507.851746 
	C481.216003,507.851746 481.289520,507.724976 481.159454,508.400818 
	C482.098877,508.918396 483.038269,509.435944 484.568237,509.888489 
	C485.278229,509.545563 485.988190,509.202667 487.261993,508.760803 
	C487.457764,508.454620 487.653534,508.148407 487.872681,507.410065 
	C487.872681,507.410065 487.889679,506.977600 488.564484,506.724854 
	C490.010986,505.431488 491.457489,504.138123 492.986816,502.418518 
	C492.986816,502.418518 493.098358,501.998901 493.631866,501.723511 
	C494.068054,501.075623 494.504242,500.427734 495.339996,499.333679 
	C498.706879,497.743195 496.810150,496.027771 495.448486,494.343048 
	C486.313385,483.040466 468.618835,479.877991 454.747406,487.710236 
	C450.402832,490.163300 447.030762,494.338745 443.276550,498.285034 
	C443.580353,498.478424 443.884125,498.671814 444.043823,498.869873 
	C444.043823,498.869873 444.173828,498.807556 444.109985,499.486572 
	C445.968628,500.335083 447.827240,501.183594 449.880676,502.631805 
	C450.308929,503.338165 450.737213,504.044556 451.525055,505.419525 
	C452.792542,505.633331 454.060059,505.847137 455.166687,506.665894 
	C455.138885,507.079956 455.111053,507.494019 455.241699,508.414398 
	C455.545441,508.571991 455.849213,508.729584 456.050232,508.947479 
	C456.050232,508.947479 456.104187,508.841217 456.280426,509.361176 
	C456.607239,509.480347 456.934052,509.599518 457.310638,509.755066 
	C457.310638,509.755066 457.301910,509.694000 457.220581,510.079559 
M701.861755,580.481873 
	C704.250122,584.272583 707.697266,585.344666 712.070129,585.093750 
	C717.550842,584.779236 723.063721,585.059143 728.561951,585.007629 
	C749.402405,584.812439 766.330200,569.010681 766.917664,549.236938 
	C767.527649,528.703796 751.716492,511.690094 730.587097,510.422272 
	C724.110596,510.033661 717.595215,510.234070 711.098083,510.265686 
	C704.163574,510.299408 702.175049,512.294434 702.159241,519.243103 
	C702.119324,536.738953 702.164429,554.235107 702.082581,571.730713 
	C702.073730,573.619934 701.455261,575.506348 701.027100,576.623413 
	C694.790283,569.549805 688.553528,562.476196 682.112427,555.170837 
	C686.560852,550.947083 691.065979,546.761536 695.462402,542.464783 
	C698.751465,539.250244 699.014832,535.586182 696.352234,532.817200 
	C693.723572,530.083496 690.254639,530.357300 686.739685,533.616699 
	C685.397644,534.861084 684.118652,536.173462 682.808594,537.452332 
	C678.137817,542.011719 673.465942,546.570007 668.000610,551.903442 
	C668.000610,548.987610 668.000977,547.362610 668.000549,545.737610 
	C667.997986,536.079651 668.027771,526.421509 667.976501,516.763794 
	C667.956177,512.939819 666.210510,510.253540 662.176392,509.999847 
	C657.914917,509.731873 655.848083,512.323059 655.267700,516.291870 
	C655.148315,517.107849 655.230774,517.954346 655.230652,518.786743 
	C655.227661,537.936157 655.224792,557.085632 655.227661,576.235046 
	C655.227844,577.232971 655.135193,578.260498 655.332947,579.223511 
	C656.055725,582.743835 658.217102,584.983093 661.858582,584.883850 
	C665.523865,584.783997 667.544128,582.429626 667.912109,578.819641 
	C668.080139,577.170837 668.143250,575.467407 667.927856,573.831787 
	C667.335144,569.330139 669.052551,566.212646 673.417542,564.161499 
	C678.873596,570.143066 684.287292,576.210999 689.852295,582.136841 
	C694.181641,586.746826 698.184204,586.050110 701.861755,580.481873 
M444.275909,578.237976 
	C445.832245,582.116211 447.690460,585.752136 452.821960,584.809875 
	C457.292175,583.989136 458.609375,579.949402 456.215851,574.383179 
	C447.824768,554.869141 439.480835,535.332825 430.790710,515.952209 
	C429.747406,513.625427 426.931549,511.165985 424.511566,510.605194 
	C421.037537,509.800171 419.121094,512.851318 417.794006,516.010986 
	C413.745453,525.650330 409.526794,535.218140 405.385773,544.818787 
	C400.980286,555.032654 396.528809,565.227295 392.221954,575.482666 
	C390.726257,579.044189 390.898193,582.388367 394.967346,584.314880 
	C398.549896,586.011047 401.849609,584.561340 403.806030,580.409973 
	C404.724640,578.460693 405.616577,576.467468 406.212555,574.405151 
	C407.046051,571.520874 408.734680,570.656311 411.699799,570.741394 
	C419.181519,570.956360 426.712585,571.282715 434.148804,570.663391 
	C439.860413,570.187683 442.882172,572.117615 444.275909,578.237976 
M945.335999,549.296570 
	C939.381226,533.509155 923.489319,525.987366 908.742981,531.976807 
	C894.305847,537.840637 887.115234,554.202759 893.096802,568.220337 
	C899.654053,583.586975 917.130615,591.171082 932.400879,582.040100 
	C936.489563,583.072327 939.601501,584.734802 942.310913,584.231384 
	C944.095154,583.899902 946.531006,580.461243 946.552734,578.391785 
	C946.651611,568.951050 945.999634,559.502441 945.335999,549.296570 
M808.494812,584.118347 
	C810.723022,583.071289 812.951294,582.024292 815.179504,580.977295 
	C815.118164,581.514282 815.056824,582.051270 814.995422,582.588257 
	C817.909485,583.207275 821.013062,584.762512 823.691956,584.219971 
	C827.460449,583.456848 828.004517,579.750671 827.934448,576.163757 
	C827.814331,570.009216 827.999695,563.848694 827.874878,557.694397 
	C827.536865,541.030090 813.408630,528.390503 797.197205,530.155518 
	C781.173889,531.900085 769.766418,547.198975 772.735229,562.962341 
	C775.791321,579.189514 791.529663,588.816101 808.494812,584.118347 
M884.217651,568.441956 
	C880.791443,566.410950 878.269714,568.473999 875.585876,570.232971 
	C866.499512,576.187927 854.079529,571.700623 852.357056,561.379822 
	C851.421082,555.771362 852.190186,549.878418 852.190186,543.502380 
	C858.322144,543.502380 864.107727,543.557068 869.891785,543.486694 
	C875.381104,543.419922 878.438416,541.109741 878.441772,537.204407 
	C878.445190,533.242981 875.362793,530.896240 870.025024,530.870422 
	C864.099060,530.841736 858.172729,530.863892 852.136597,530.863892 
	C852.014587,528.710815 851.908569,527.394409 851.873596,526.076111 
	C851.833923,524.578613 851.917969,523.076355 851.842651,521.581482 
	C851.620056,517.164307 848.895630,514.091187 845.348755,514.185791 
	C841.690369,514.283264 839.085571,517.245544 839.063171,521.762451 
	C839.000549,534.416321 838.651978,547.088379 839.195435,559.719727 
	C839.681763,571.021912 845.788391,579.214905 856.203064,583.526306 
	C865.984802,587.575806 875.479553,586.535522 883.834351,579.566772 
	C887.900757,576.174988 888.051331,573.296326 884.217651,568.441956 
M617.545288,542.869141 
	C626.098816,543.488708 631.036743,548.403809 631.246948,556.859131 
	C631.416565,563.680786 631.205872,570.511047 631.306702,577.335449 
	C631.379944,582.285828 633.872620,585.044739 637.907227,584.993164 
	C641.774780,584.943726 643.958862,582.343323 643.996033,577.494446 
	C644.050842,570.336182 644.140686,563.173523 643.963745,556.018982 
	C643.608765,541.665100 632.182190,530.217712 618.240479,530.054871 
	C604.058044,529.889221 592.496399,540.728027 591.803955,555.189941 
	C591.453918,562.499329 591.616699,569.836365 591.656372,577.160278 
	C591.684021,582.250732 593.940369,584.930176 598.009094,585.017212 
	C602.011230,585.102783 604.126038,582.478394 604.178650,577.136108 
	C604.242615,570.643738 604.081909,564.147583 604.235352,557.658142 
	C604.440186,548.994690 608.569702,544.231018 617.545288,542.869141 
M533.896667,512.764587 
	C533.575439,513.690552 532.976013,514.615845 532.973999,515.542480 
	C532.936707,533.174683 532.677368,550.814148 533.107422,568.436401 
	C533.333801,577.711548 541.821350,584.881531 551.205688,584.950562 
	C554.752441,584.976685 557.503723,583.772583 558.028992,579.911560 
	C558.587097,575.808655 556.716736,572.823242 552.575256,572.464355 
	C546.638550,571.949829 545.285889,568.645142 545.343445,563.391846 
	C545.511353,548.088257 545.569214,532.778198 545.237915,517.480042 
	C545.186890,515.124573 543.379578,511.888672 541.414307,510.780579 
	C539.809692,509.875793 536.756775,511.539642 533.896667,512.764587 
M505.525452,533.960876 
	C495.807983,539.162231 490.293427,547.245483 489.598206,558.239075 
	C489.178253,564.879150 489.314026,571.566101 489.463470,578.226074 
	C489.554230,582.270752 491.811218,584.964661 496.005951,584.890747 
	C500.279907,584.815491 502.073486,581.993896 502.072815,577.895752 
	C502.071899,572.062988 501.966980,566.227295 502.113251,560.398071 
	C502.342224,551.271973 507.845856,545.117371 516.827332,543.689270 
	C517.814636,543.532227 518.822021,543.461426 519.789124,543.224304 
	C523.290100,542.365906 525.395447,540.290100 525.066101,536.494873 
	C524.738525,532.720581 522.444275,530.543823 518.754578,530.979065 
	C514.525696,531.477966 510.383545,532.712769 505.525452,533.960876 
M477.551666,537.254761 
	C476.953583,533.359375 475.157196,530.563904 470.770782,530.934143 
	C467.106201,531.243469 465.017029,533.629150 464.988190,537.231079 
	C464.877686,551.025879 464.872406,564.822754 464.985748,578.617554 
	C465.017853,582.526978 467.426575,584.850586 471.285400,584.906189 
	C474.991791,584.959656 476.988861,582.560669 477.486420,579.000549 
	C477.692017,577.529663 477.616150,576.015198 477.616882,574.520508 
	C477.622894,562.386780 477.616730,550.253113 477.551666,537.254761 
M567.089050,558.499939 
	C567.090149,564.811768 567.091431,571.123657 567.092285,577.435486 
	C567.092834,581.678223 568.946899,584.522705 573.351440,584.929993 
	C577.026855,585.269897 579.823303,582.227295 579.858215,577.747498 
	C579.960388,564.626343 579.945923,551.503601 579.875000,538.382019 
	C579.853088,534.329773 578.375305,531.122437 573.687378,530.907043 
	C569.706604,530.724304 567.178467,533.672974 567.110352,538.564758 
	C567.022400,544.875366 567.088989,551.188171 567.089050,558.499939 
M478.049805,516.750427 
	C475.808868,511.337402 471.506897,509.053070 467.944916,512.058228 
	C466.053314,513.654114 464.194672,517.428162 464.852509,519.354614 
	C465.638672,521.656921 469.036255,524.336975 471.473297,524.514771 
	C475.332977,524.796326 478.126740,522.031250 478.049805,516.750427 
M580.376160,518.339050 
	C580.359985,514.021851 578.096558,510.976074 573.954102,511.205475 
	C571.555115,511.338348 568.520996,513.489929 567.156616,515.619629 
	C565.166504,518.725952 567.574646,522.899719 571.283997,524.305847 
	C575.055725,525.735535 578.238831,523.871033 580.376160,518.339050 
z"/>
<path fill="#D52C2A" opacity="1.000000" stroke="none" 
	d="
M568.879639,341.800049 
	C568.936279,341.502289 569.079102,341.254120 569.781372,340.891449 
	C570.437744,340.405212 570.620728,340.083069 570.875183,339.810913 
	C570.946594,339.860901 570.778015,339.816467 571.067871,339.730652 
	C571.576599,339.107666 571.795532,338.570435 572.111694,337.813110 
	C572.380249,337.307343 572.619202,337.099548 573.169556,336.880127 
	C573.596313,336.507172 573.779419,336.223663 573.980042,335.968994 
	C573.997559,335.997864 573.939941,335.962677 574.181519,335.874084 
	C574.601501,335.502197 574.779968,335.218872 574.977783,334.966583 
	C574.997070,334.997620 574.935181,334.958588 575.177368,334.871155 
	C575.599243,334.500916 575.778870,334.218079 575.978027,333.966400 
	C575.997559,333.997559 575.934875,333.958923 576.178589,333.866516 
	C576.597534,333.483795 576.772766,333.193451 577.064331,332.701782 
	C577.374939,332.248932 577.618591,332.061615 578.155640,331.858856 
	C578.592224,331.505798 578.784729,331.232361 578.987305,330.980469 
	C578.997437,331.002014 578.962036,330.969513 579.272949,330.957428 
	C579.936646,330.523193 580.289551,330.101013 580.678223,329.674866 
	C580.714050,329.670929 580.753235,329.610565 581.015869,329.622589 
	C581.432312,329.344482 581.586121,329.054352 581.783325,328.833252 
	C581.826660,328.902344 581.793152,328.742737 582.057983,328.721069 
	C582.497986,328.410217 582.673218,328.121033 582.931030,327.897095 
	C583.013611,327.962341 582.839600,327.846405 583.096008,327.794495 
	C583.549438,327.467041 583.746521,327.191528 583.959351,326.958344 
	C583.975159,327.000671 583.908447,326.939789 584.155151,326.872711 
	C584.604858,326.542175 584.807922,326.278717 585.005737,326.008698 
	C585.000549,326.002106 585.014221,326.013214 585.242798,325.927551 
	C585.759155,325.654175 585.942566,325.393158 586.021606,325.058807 
	C586.021606,325.058807 585.961243,324.898773 586.129822,324.873169 
	C586.298401,324.847565 586.713562,324.824188 586.987549,324.792419 
	C587.497925,324.492584 587.734314,324.224487 587.979004,323.966919 
	C587.987305,323.977417 587.957092,323.960114 588.330933,323.972473 
	C590.080505,322.912109 591.456360,321.839355 592.832153,320.766602 
	C592.832153,320.766602 592.897522,320.926208 593.098267,320.855469 
	C593.543945,320.551300 593.788940,320.317932 594.039307,320.051178 
	C594.044739,320.017792 594.102661,320.053558 594.392639,320.041534 
	C595.135803,319.706696 595.589050,319.383850 596.041992,319.042664 
	C596.041748,319.024323 596.064026,319.049896 596.418579,319.051147 
	C598.146240,318.309692 599.519226,317.567047 600.885254,316.890991 
	C600.878296,316.957581 600.837097,316.830139 601.160278,316.867249 
	C601.981201,316.560486 602.478943,316.216644 603.110168,315.782227 
	C603.243652,315.691681 603.564026,315.654816 603.851807,315.686249 
	C604.427429,315.481140 604.715210,315.244598 605.001465,315.004028 
	C605.000000,315.000000 605.008911,315.005859 605.346680,315.024231 
	C606.443970,314.682343 607.203491,314.322021 607.975769,313.979919 
	C607.988525,313.998108 607.980530,313.955872 608.318848,313.984955 
	C609.438049,313.673187 610.218872,313.332367 610.999878,312.996399 
	C611.000000,313.001221 610.989502,312.994995 611.380371,313.021912 
	C613.116089,312.604156 614.460999,312.159515 615.811279,311.751831 
	C615.816711,311.788818 615.870728,311.737061 616.291687,311.822876 
	C619.467957,311.235443 622.223328,310.562195 625.298462,309.808533 
	C626.274414,309.696625 626.930542,309.665222 628.080322,309.736938 
	C637.378052,309.662720 646.182129,309.485352 655.264526,309.292419 
	C656.065796,309.432770 656.588867,309.588684 657.326416,310.087738 
	C660.061340,310.599518 662.581726,310.768127 665.410645,310.944366 
	C666.124390,311.019531 666.529602,311.087067 666.873352,311.517395 
	C664.510071,312.691681 662.208374,313.503204 659.488403,314.422974 
	C657.042358,314.577240 655.014587,314.623322 652.658752,314.460907 
	C648.246033,314.874451 644.161316,315.496460 640.056519,316.073090 
	C640.036499,316.027710 640.067871,316.112427 639.677979,316.060913 
	C637.868469,316.364716 636.448914,316.720093 635.020691,317.037659 
	C635.012024,316.999817 635.058472,317.062073 634.687622,317.018250 
	C633.221680,317.339722 632.126587,317.705017 630.867249,318.153015 
	C630.702942,318.235687 630.336792,318.271759 629.925903,318.194519 
	C627.686829,318.765442 625.858704,319.413666 623.866821,320.144257 
	C623.703064,320.226624 623.340027,320.277618 623.006775,320.209229 
	C622.132202,320.460236 621.590820,320.779663 621.029968,321.050629 
	C621.010498,321.002136 621.073120,321.085907 620.766724,321.060211 
	C619.969177,321.348267 619.477966,321.662018 618.993164,321.988495 
	C618.999634,322.001221 618.979553,321.978943 618.612183,321.965912 
	C617.238037,322.400452 616.231262,322.848022 615.218628,323.183624 
	C615.212891,323.071655 615.205139,323.290771 614.950562,323.286591 
	C614.472656,323.523590 614.249329,323.764771 614.017090,324.006775 
	C614.008240,324.007568 613.998169,324.021790 613.656616,324.007324 
	C612.616089,324.423950 611.917114,324.855042 611.229248,325.229797 
	C611.240417,325.173462 611.197876,325.273163 610.941345,325.270233 
	C610.453857,325.506073 610.222900,325.744843 609.995911,325.992432 
	C609.999878,326.001221 609.984009,325.987091 609.686646,325.987091 
	C608.917175,326.305664 608.445129,326.624237 607.974243,326.974243 
	C607.975525,327.005646 607.940308,326.953461 607.640869,326.965271 
	C606.906494,327.342224 606.471497,327.707336 606.021301,328.036469 
	C606.006165,328.000488 606.060120,328.057068 605.748901,328.054138 
	C605.003235,328.431763 604.568909,328.812317 604.090210,329.092224 
	C604.045837,328.991608 604.150513,329.186798 603.813110,329.156891 
	C602.696594,329.814697 601.917419,330.502380 601.138306,331.190094 
	C601.138306,331.190094 601.068115,331.034882 600.866577,331.115540 
	C600.429138,331.436066 600.193359,331.675934 599.958130,331.943848 
	C599.958801,331.971893 599.911621,331.943176 599.615051,331.970581 
	C598.916931,332.386871 598.515320,332.775757 598.057434,333.112549 
	C598.001099,333.060516 598.137878,333.129944 597.880493,333.186829 
	C597.423950,333.521698 597.224854,333.799683 596.894348,334.279175 
	C596.538940,334.724640 596.266174,334.886414 595.635864,334.994202 
	C594.932068,335.440186 594.537048,335.858002 593.986023,336.489319 
	C593.537964,337.123169 593.245789,337.543579 592.953674,337.963959 
	C592.953674,337.963959 592.999817,338.000641 592.677979,338.082947 
	C591.196472,339.401947 590.036865,340.638672 588.877258,341.875366 
	C588.877258,341.875366 588.954285,342.009491 588.668945,342.017792 
	C588.206787,342.326263 588.029907,342.626404 587.853027,342.926544 
	C587.547485,343.026215 587.304016,343.208771 586.796692,343.785645 
	C586.323608,344.400269 586.176636,344.703461 586.029663,345.006653 
	C586.029663,345.006653 585.998291,344.997101 585.689209,345.124695 
	C583.567566,347.821167 581.755066,350.390045 579.942627,352.958923 
	C579.942627,352.958923 580.000000,353.000000 579.673340,353.130219 
	C559.632690,382.962372 568.858582,422.793243 584.840698,443.252136 
	C588.988770,434.702942 593.028259,426.377472 597.067749,418.052002 
	C597.067810,418.052002 597.005310,417.996735 597.305969,417.828979 
	C598.445618,415.805267 599.284546,413.949371 600.123535,412.093445 
	C600.123535,412.093445 600.027100,411.988647 600.348511,411.858215 
	C606.468262,400.166840 612.266602,388.605865 618.064941,377.044922 
	C618.064941,377.044922 618.000488,377.001221 618.309082,376.832245 
	C622.799744,368.546814 626.943787,360.410278 631.204590,352.335297 
	C631.853210,351.106140 633.013916,349.259583 633.986145,349.228882 
	C641.972961,348.976593 649.971252,349.086456 658.820374,349.086456 
	C657.360718,351.535156 656.543335,352.954224 655.681030,354.345428 
	C647.728760,367.175659 639.768799,380.001129 631.557739,393.005280 
	C630.827332,394.081482 630.350159,394.980377 629.596985,396.097443 
	C628.485657,397.793854 627.650330,399.272156 626.414734,401.458740 
	C631.620422,401.738586 635.826111,401.964691 640.244019,402.202209 
	C640.244019,407.585754 640.244019,412.356781 640.244019,417.127777 
	C640.244019,417.127777 640.124084,417.058136 639.614746,417.036102 
	C631.739990,417.084381 624.374573,417.154663 617.009155,417.224945 
	C616.572754,417.188995 616.136353,417.153076 615.022400,417.094818 
	C612.514160,420.018646 610.683472,422.964844 608.852844,425.911041 
	C608.852844,425.911041 608.983337,425.987335 608.644531,426.129211 
	C603.405701,436.358276 598.505676,446.445435 593.605591,456.532593 
	C593.605591,456.532593 593.564941,456.643738 593.293945,456.909912 
	C591.332214,460.771790 589.641479,464.367462 587.950745,467.963165 
	C587.950745,467.963165 587.998779,468.001221 587.701233,468.172180 
	C586.672974,469.555878 585.942322,470.768646 584.961670,472.396271 
	C581.901184,470.017975 579.123291,467.859192 576.345337,465.700439 
	C576.345337,465.700439 576.181641,465.819763 576.126892,465.437866 
	C574.390991,463.360626 572.709778,461.665253 571.028625,459.969849 
	C571.028625,459.969849 571.000000,460.000000 570.926025,459.670715 
	C569.593750,457.870361 568.335449,456.399261 567.077209,454.928192 
	C567.077209,454.928192 567.004883,454.997559 566.976624,454.622009 
	C545.866089,421.447815 544.200256,387.651459 562.038696,352.755249 
	C562.517029,352.059113 562.695740,351.476013 562.932373,350.942871 
	C562.990234,350.992889 562.859070,350.914185 563.132568,350.786438 
	C563.609619,350.113190 563.813171,349.567749 564.007446,349.011719 
	C563.998291,349.001129 564.023499,349.011414 564.247986,348.891296 
	C564.601074,348.467560 564.729675,348.163971 564.905029,347.929749 
	C564.951843,347.999054 564.829224,347.885284 565.108398,347.765076 
	C565.592896,347.098663 565.798157,346.552460 566.001709,346.003174 
	C566.000000,346.000092 566.005615,346.003052 566.235596,345.867004 
	C566.562317,345.402161 566.659058,345.073364 566.755798,344.744568 
	C566.755798,344.744568 566.937744,344.877167 567.172363,344.762787 
	C567.897888,343.698944 568.388733,342.749481 568.879639,341.800049 
z"/>
<path fill="#0E0F11" opacity="1.000000" stroke="none" 
	d="
M661.145508,350.404205 
	C661.721924,351.789001 662.812927,353.179626 662.797363,354.557709 
	C662.574402,374.218506 662.199646,393.877594 661.869690,413.537201 
	C661.839355,415.342010 661.865601,417.147736 661.865601,419.711243 
	C687.073608,419.711243 711.968689,419.711243 737.978394,419.711243 
	C727.919678,447.626587 715.597595,472.645264 689.360657,487.182373 
	C659.168030,503.911194 628.975647,496.886200 599.091003,485.482300 
	C608.464539,486.272095 617.828369,487.576294 627.213440,487.752960 
	C652.886719,488.236145 676.217773,481.839874 693.639282,461.623535 
	C700.069397,454.161774 705.044067,445.445709 711.313843,436.389923 
	C687.429749,436.389923 665.241394,436.389923 642.396118,436.389923 
	C642.396118,430.443359 642.396118,424.189880 642.475647,417.108704 
	C642.529846,411.187195 642.504456,406.093384 642.586304,400.540466 
	C642.693604,394.048676 642.693726,388.015991 642.796326,381.630951 
	C648.216125,372.761261 653.571533,364.267303 658.817017,355.705963 
	C659.816101,354.075317 660.380493,352.178314 661.145508,350.404205 
z"/>
<path fill="#D42C24" opacity="1.000000" stroke="none" 
	d="
M701.119629,577.394043 
	C701.455261,575.506348 702.073730,573.619934 702.082581,571.730713 
	C702.164429,554.235107 702.119324,536.738953 702.159241,519.243103 
	C702.175049,512.294434 704.163574,510.299408 711.098083,510.265686 
	C717.595215,510.234070 724.110596,510.033661 730.587097,510.422272 
	C751.716492,511.690094 767.527649,528.703796 766.917664,549.236938 
	C766.330200,569.010681 749.402405,584.812439 728.561951,585.007629 
	C723.063721,585.059143 717.550842,584.779236 712.070129,585.093750 
	C707.697266,585.344666 704.250122,584.272583 701.441528,580.140808 
	C701.054077,578.997864 701.086853,578.195923 701.119629,577.394043 
M735.486328,571.497070 
	C754.971985,564.869202 760.457825,543.427429 746.206726,529.950562 
	C737.126648,521.363831 726.159180,522.899963 715.346802,523.273315 
	C715.346802,539.809998 715.346802,555.867859 715.346802,571.752258 
	C722.063477,571.752258 728.368530,571.752258 735.486328,571.497070 
z"/>
<path fill="#0F1012" opacity="1.000000" stroke="none" 
	d="
M444.138184,577.880859 
	C442.882172,572.117615 439.860413,570.187683 434.148804,570.663391 
	C426.712585,571.282715 419.181519,570.956360 411.699799,570.741394 
	C408.734680,570.656311 407.046051,571.520874 406.212555,574.405151 
	C405.616577,576.467468 404.724640,578.460693 403.806030,580.409973 
	C401.849609,584.561340 398.549896,586.011047 394.967346,584.314880 
	C390.898193,582.388367 390.726257,579.044189 392.221954,575.482666 
	C396.528809,565.227295 400.980286,555.032654 405.385773,544.818787 
	C409.526794,535.218140 413.745453,525.650330 417.794006,516.010986 
	C419.121094,512.851318 421.037537,509.800171 424.511566,510.605194 
	C426.931549,511.165985 429.747406,513.625427 430.790710,515.952209 
	C439.480835,535.332825 447.824768,554.869141 456.215851,574.383179 
	C458.609375,579.949402 457.292175,583.989136 452.821960,584.809875 
	C447.690460,585.752136 445.832245,582.116211 444.138184,577.880859 
M418.408295,544.918457 
	C416.663513,549.161987 414.918732,553.405579 413.155243,557.694641 
	C420.771912,557.694641 427.950684,557.694641 435.746765,557.694641 
	C431.950867,548.586731 428.369263,539.992981 424.487488,530.678894 
	C422.361786,535.718506 420.564606,539.979370 418.408295,544.918457 
z"/>
<path fill="#D42C24" opacity="1.000000" stroke="none" 
	d="
M945.476807,549.676636 
	C945.999634,559.502441 946.651611,568.951050 946.552734,578.391785 
	C946.531006,580.461243 944.095154,583.899902 942.310913,584.231384 
	C939.601501,584.734802 936.489563,583.072327 932.400879,582.040100 
	C917.130615,591.171082 899.654053,583.586975 893.096802,568.220337 
	C887.115234,554.202759 894.305847,537.840637 908.742981,531.976807 
	C923.489319,525.987366 939.381226,533.509155 945.476807,549.676636 
M923.391785,572.276978 
	C932.834656,568.164185 936.547729,559.101929 932.184875,550.816040 
	C928.164917,543.181335 918.705627,540.566223 911.131409,544.995605 
	C905.083496,548.532349 902.347961,556.077942 904.690613,562.761963 
	C907.311157,570.239258 913.685791,573.719910 923.391785,572.276978 
z"/>
<path fill="#D42B23" opacity="1.000000" stroke="none" 
	d="
M808.119385,584.247070 
	C791.529663,588.816101 775.791321,579.189514 772.735229,562.962341 
	C769.766418,547.198975 781.173889,531.900085 797.197205,530.155518 
	C813.408630,528.390503 827.536865,541.030090 827.874878,557.694397 
	C827.999695,563.848694 827.814331,570.009216 827.934448,576.163757 
	C828.004517,579.750671 827.460449,583.456848 823.691956,584.219971 
	C821.013062,584.762512 817.909485,583.207275 814.995422,582.588257 
	C815.056824,582.051270 815.118164,581.514282 815.179504,580.977295 
	C812.951294,582.024292 810.723022,583.071289 808.119385,584.247070 
M804.582214,543.426941 
	C792.900391,540.129700 782.379639,550.301514 785.527283,561.879456 
	C787.396423,568.754639 793.759949,573.292236 800.795227,572.988525 
	C807.560913,572.696594 813.540771,567.575928 814.859314,560.945435 
	C816.311157,553.644348 812.851746,547.408691 804.582214,543.426941 
z"/>
<path fill="#0F1012" opacity="1.000000" stroke="none" 
	d="
M701.073364,577.008728 
	C701.086853,578.195923 701.054077,578.997864 701.059326,580.089722 
	C698.184204,586.050110 694.181641,586.746826 689.852295,582.136841 
	C684.287292,576.210999 678.873596,570.143066 673.417542,564.161499 
	C669.052551,566.212646 667.335144,569.330139 667.927856,573.831787 
	C668.143250,575.467407 668.080139,577.170837 667.912109,578.819641 
	C667.544128,582.429626 665.523865,584.783997 661.858582,584.883850 
	C658.217102,584.983093 656.055725,582.743835 655.332947,579.223511 
	C655.135193,578.260498 655.227844,577.232971 655.227661,576.235046 
	C655.224792,557.085632 655.227661,537.936157 655.230652,518.786743 
	C655.230774,517.954346 655.148315,517.107849 655.267700,516.291870 
	C655.848083,512.323059 657.914917,509.731873 662.176392,509.999847 
	C666.210510,510.253540 667.956177,512.939819 667.976501,516.763794 
	C668.027771,526.421509 667.997986,536.079651 668.000549,545.737610 
	C668.000977,547.362610 668.000610,548.987610 668.000610,551.903442 
	C673.465942,546.570007 678.137817,542.011719 682.808594,537.452332 
	C684.118652,536.173462 685.397644,534.861084 686.739685,533.616699 
	C690.254639,530.357300 693.723572,530.083496 696.352234,532.817200 
	C699.014832,535.586182 698.751465,539.250244 695.462402,542.464783 
	C691.065979,546.761536 686.560852,550.947083 682.112427,555.170837 
	C688.553528,562.476196 694.790283,569.549805 701.073364,577.008728 
z"/>
<path fill="#D42B23" opacity="1.000000" stroke="none" 
	d="
M884.521973,568.653137 
	C888.051331,573.296326 887.900757,576.174988 883.834351,579.566772 
	C875.479553,586.535522 865.984802,587.575806 856.203064,583.526306 
	C845.788391,579.214905 839.681763,571.021912 839.195435,559.719727 
	C838.651978,547.088379 839.000549,534.416321 839.063171,521.762451 
	C839.085571,517.245544 841.690369,514.283264 845.348755,514.185791 
	C848.895630,514.091187 851.620056,517.164307 851.842651,521.581482 
	C851.917969,523.076355 851.833923,524.578613 851.873596,526.076111 
	C851.908569,527.394409 852.014587,528.710815 852.136597,530.863892 
	C858.172729,530.863892 864.099060,530.841736 870.025024,530.870422 
	C875.362793,530.896240 878.445190,533.242981 878.441772,537.204407 
	C878.438416,541.109741 875.381104,543.419922 869.891785,543.486694 
	C864.107727,543.557068 858.322144,543.502380 852.190186,543.502380 
	C852.190186,549.878418 851.421082,555.771362 852.357056,561.379822 
	C854.079529,571.700623 866.499512,576.187927 875.585876,570.232971 
	C878.269714,568.473999 880.791443,566.410950 884.521973,568.653137 
z"/>
<path fill="#0F1011" opacity="1.000000" stroke="none" 
	d="
M617.128845,542.886475 
	C608.569702,544.231018 604.440186,548.994690 604.235352,557.658142 
	C604.081909,564.147583 604.242615,570.643738 604.178650,577.136108 
	C604.126038,582.478394 602.011230,585.102783 598.009094,585.017212 
	C593.940369,584.930176 591.684021,582.250732 591.656372,577.160278 
	C591.616699,569.836365 591.453918,562.499329 591.803955,555.189941 
	C592.496399,540.728027 604.058044,529.889221 618.240479,530.054871 
	C632.182190,530.217712 643.608765,541.665100 643.963745,556.018982 
	C644.140686,563.173523 644.050842,570.336182 643.996033,577.494446 
	C643.958862,582.343323 641.774780,584.943726 637.907227,584.993164 
	C633.872620,585.044739 631.379944,582.285828 631.306702,577.335449 
	C631.205872,570.511047 631.416565,563.680786 631.246948,556.859131 
	C631.036743,548.403809 626.098816,543.488708 617.128845,542.886475 
z"/>
<path fill="#0D0E0F" opacity="1.000000" stroke="none" 
	d="
M534.121338,512.414612 
	C536.756775,511.539642 539.809692,509.875793 541.414307,510.780579 
	C543.379578,511.888672 545.186890,515.124573 545.237915,517.480042 
	C545.569214,532.778198 545.511353,548.088257 545.343445,563.391846 
	C545.285889,568.645142 546.638550,571.949829 552.575256,572.464355 
	C556.716736,572.823242 558.587097,575.808655 558.028992,579.911560 
	C557.503723,583.772583 554.752441,584.976685 551.205688,584.950562 
	C541.821350,584.881531 533.333801,577.711548 533.107422,568.436401 
	C532.677368,550.814148 532.936707,533.174683 532.973999,515.542480 
	C532.976013,514.615845 533.575439,513.690552 534.121338,512.414612 
z"/>
<path fill="#121214" opacity="1.000000" stroke="none" 
	d="
M505.864563,533.794373 
	C510.383545,532.712769 514.525696,531.477966 518.754578,530.979065 
	C522.444275,530.543823 524.738525,532.720581 525.066101,536.494873 
	C525.395447,540.290100 523.290100,542.365906 519.789124,543.224304 
	C518.822021,543.461426 517.814636,543.532227 516.827332,543.689270 
	C507.845856,545.117371 502.342224,551.271973 502.113251,560.398071 
	C501.966980,566.227295 502.071899,572.062988 502.072815,577.895752 
	C502.073486,581.993896 500.279907,584.815491 496.005951,584.890747 
	C491.811218,584.964661 489.554230,582.270752 489.463470,578.226074 
	C489.314026,571.566101 489.178253,564.879150 489.598206,558.239075 
	C490.293427,547.245483 495.807983,539.162231 505.864563,533.794373 
z"/>
<path fill="#121214" opacity="1.000000" stroke="none" 
	d="
M477.582947,537.687073 
	C477.616730,550.253113 477.622894,562.386780 477.616882,574.520508 
	C477.616150,576.015198 477.692017,577.529663 477.486420,579.000549 
	C476.988861,582.560669 474.991791,584.959656 471.285400,584.906189 
	C467.426575,584.850586 465.017853,582.526978 464.985748,578.617554 
	C464.872406,564.822754 464.877686,551.025879 464.988190,537.231079 
	C465.017029,533.629150 467.106201,531.243469 470.770782,530.934143 
	C475.157196,530.563904 476.953583,533.359375 477.582947,537.687073 
z"/>
<path fill="#121214" opacity="1.000000" stroke="none" 
	d="
M567.089050,558.000000 
	C567.088989,551.188171 567.022400,544.875366 567.110352,538.564758 
	C567.178467,533.672974 569.706604,530.724304 573.687378,530.907043 
	C578.375305,531.122437 579.853088,534.329773 579.875000,538.382019 
	C579.945923,551.503601 579.960388,564.626343 579.858215,577.747498 
	C579.823303,582.227295 577.026855,585.269897 573.351440,584.929993 
	C568.946899,584.522705 567.092834,581.678223 567.092285,577.435486 
	C567.091431,571.123657 567.090149,564.811768 567.089050,558.000000 
z"/>
<path fill="#C93831" opacity="1.000000" stroke="none" 
	d="
M443.213562,497.725830 
	C447.030762,494.338745 450.402832,490.163300 454.747406,487.710236 
	C468.618835,479.877991 486.313385,483.040466 495.448486,494.343048 
	C496.810150,496.027771 498.706879,497.743195 494.805786,499.550079 
	C493.967255,499.660461 493.528412,499.649017 493.377747,499.434875 
	C482.619568,484.146179 459.882172,485.667755 448.729980,498.110809 
	C447.938019,498.994415 445.725677,498.605011 444.173828,498.807556 
	C444.173828,498.807556 444.043823,498.869873 444.044373,498.610779 
	C443.767853,498.143097 443.490723,497.934479 443.213562,497.725830 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M660.978882,350.037781 
	C660.380493,352.178314 659.816101,354.075317 658.817017,355.705963 
	C653.571533,364.267303 648.216125,372.761261 642.441772,381.648438 
	C640.748352,383.192749 639.401001,384.272369 638.299744,385.562134 
	C636.187378,388.035980 634.214783,390.629120 632.091064,393.086426 
	C631.997864,393.000092 631.811035,392.827942 631.811035,392.827942 
	C639.768799,380.001129 647.728760,367.175659 655.681030,354.345428 
	C656.543335,352.954224 657.360718,351.535156 658.820374,349.086456 
	C649.971252,349.086456 641.972961,348.976593 633.986145,349.228882 
	C633.013916,349.259583 631.853210,351.106140 631.204590,352.335297 
	C626.943787,360.410278 622.799744,368.546814 618.335327,376.860992 
	C619.459961,372.904694 620.565552,368.615051 622.356628,364.633850 
	C624.243408,360.440063 627.435913,356.759857 628.882324,352.459778 
	C630.860046,346.579926 635.560608,346.587921 639.968628,346.369019 
	C645.065796,346.115936 650.231995,346.575134 655.319275,347.154358 
	C657.221191,347.370911 658.985352,348.796844 660.978882,350.037781 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M561.739075,352.868256 
	C544.200256,387.651459 545.866089,421.447815 567.014587,454.586609 
	C564.140930,451.349121 560.349976,448.178467 558.410645,444.122467 
	C550.582336,427.749847 546.750061,410.352570 548.162659,392.174988 
	C548.950256,382.040344 550.646973,371.893463 555.078674,362.576904 
	C557.776672,356.904938 561.154114,351.556183 564.645020,346.059875 
	C565.063599,346.057465 565.226440,346.220337 565.150391,346.496094 
	C564.992615,347.143005 564.910889,347.514160 564.829224,347.885284 
	C564.829224,347.885284 564.951843,347.999054 564.682495,348.050232 
	C564.283264,348.404755 564.153381,348.708099 564.023499,349.011414 
	C564.023499,349.011414 563.998291,349.001129 563.755737,349.161377 
	C563.295166,349.852478 563.077148,350.383331 562.859070,350.914185 
	C562.859070,350.914185 562.990234,350.992889 562.684937,351.107666 
	C562.166077,351.771027 561.952576,352.319641 561.739075,352.868256 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M596.718750,418.086914 
	C593.028259,426.377472 588.988770,434.702942 584.840698,443.252136 
	C568.858582,422.793243 559.632690,382.962372 579.650391,353.103394 
	C578.021057,357.813293 575.852478,362.602783 574.220032,367.568573 
	C572.537842,372.685638 570.620911,377.904327 570.130188,383.201172 
	C568.598389,399.733734 571.736816,415.566864 578.999268,430.521118 
	C580.341064,433.283997 582.068176,435.859772 583.904846,439.015808 
	C588.555481,431.220276 592.462585,424.671051 596.718750,418.086914 
z"/>
<path fill="#C93831" opacity="1.000000" stroke="none" 
	d="
M451.165466,504.750916 
	C450.737213,504.044556 450.308929,503.338165 449.988434,502.047150 
	C461.632538,486.806915 484.307404,489.866608 493.098358,501.998901 
	C493.098358,501.998901 492.986816,502.418518 492.635559,502.715942 
	C490.276154,504.958710 488.905823,504.122101 486.998993,502.435822 
	C477.399109,493.946198 464.930969,494.180450 455.386200,502.643036 
	C454.249756,503.650665 452.585205,504.062775 451.165466,504.750916 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M493.365112,501.861206 
	C484.307404,489.866608 461.632538,486.806915 449.891052,501.747314 
	C447.827240,501.183594 445.968628,500.335083 444.141907,499.147034 
	C445.725677,498.605011 447.938019,498.994415 448.729980,498.110809 
	C459.882172,485.667755 482.619568,484.146179 493.377747,499.434875 
	C493.528412,499.649017 493.967255,499.660461 494.606018,499.773132 
	C494.504242,500.427734 494.068054,501.075623 493.365112,501.861206 
z"/>
<path fill="#121214" opacity="1.000000" stroke="none" 
	d="
M478.068481,517.151917 
	C478.126740,522.031250 475.332977,524.796326 471.473297,524.514771 
	C469.036255,524.336975 465.638672,521.656921 464.852509,519.354614 
	C464.194672,517.428162 466.053314,513.654114 467.944916,512.058228 
	C471.506897,509.053070 475.808868,511.337402 478.068481,517.151917 
z"/>
<path fill="#121214" opacity="1.000000" stroke="none" 
	d="
M580.317017,518.694214 
	C578.238831,523.871033 575.055725,525.735535 571.283997,524.305847 
	C567.574646,522.899719 565.166504,518.725952 567.156616,515.619629 
	C568.520996,513.489929 571.555115,511.338348 573.954102,511.205475 
	C578.096558,510.976074 580.359985,514.021851 580.317017,518.694214 
z"/>
<path fill="#C93831" opacity="1.000000" stroke="none" 
	d="
M455.083252,507.908081 
	C455.111053,507.494019 455.138885,507.079956 455.531372,506.211121 
	C466.283844,496.545502 477.459076,496.972076 487.889679,506.977600 
	C487.889679,506.977600 487.872681,507.410065 487.606506,507.714661 
	C487.126312,508.299438 486.912231,508.579590 486.698181,508.859741 
	C485.988190,509.202667 485.278229,509.545563 484.120911,509.661865 
	C482.878876,508.865112 482.084198,508.295044 481.289520,507.724976 
	C481.289520,507.724976 481.216003,507.851746 481.065643,507.502502 
	C479.971527,506.728607 479.027771,506.303986 478.084015,505.879395 
	C478.084015,505.879395 477.988892,505.921356 477.798828,505.620300 
	C473.284821,503.553406 469.000519,503.118103 464.806091,505.700470 
	C464.806091,505.700470 464.751556,505.734070 464.433929,505.740204 
	C463.298737,506.321320 462.481201,506.896271 461.663635,507.471252 
	C461.663635,507.471222 461.649841,507.559418 461.336914,507.649109 
	C460.670929,508.159729 460.317841,508.580597 459.964722,509.001465 
	C459.481293,509.295898 458.997833,509.590363 458.094086,509.809265 
	C457.673767,509.733765 457.301910,509.694000 457.301910,509.694000 
	C457.301910,509.694000 457.310638,509.755066 457.173889,509.502930 
	C456.726135,509.114258 456.415161,508.977722 456.104187,508.841217 
	C456.104187,508.841217 456.050232,508.947479 456.006317,508.676178 
	C455.669342,508.239288 455.376312,508.073700 455.083252,507.908081 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M630.336792,318.271759 
	C630.336792,318.271759 630.702942,318.235687 631.226074,318.162354 
	C632.852234,317.746674 633.955383,317.404388 635.058472,317.062073 
	C635.058472,317.062073 635.012024,316.999817 635.401306,317.063354 
	C637.216370,316.788696 638.642090,316.450562 640.067871,316.112427 
	C640.067871,316.112427 640.036499,316.027710 640.489868,316.084320 
	C644.957764,315.650421 648.972290,315.159912 652.986755,314.669373 
	C655.014587,314.623322 657.042358,314.577240 659.942505,314.571106 
	C667.328247,314.611023 673.841553,314.611023 680.691772,314.611023 
	C679.831787,313.940216 679.319458,313.540558 678.807129,313.140930 
	C681.997742,313.807892 685.188293,314.474854 688.378906,315.141846 
	C688.253418,315.976929 688.127869,316.812042 688.002380,317.647125 
	C684.046753,317.165283 680.088196,316.258270 676.136230,316.286163 
	C666.585815,316.353455 657.023560,316.636780 647.496399,317.281189 
	C642.968262,317.587463 638.471924,318.760132 634.028076,319.820862 
	C627.518066,321.374786 621.068176,323.180603 614.238342,324.657318 
	C613.884033,324.435577 613.998169,324.021790 613.998169,324.021790 
	C613.998169,324.021790 614.008240,324.007568 614.262207,323.979858 
	C614.859863,323.850555 615.089539,323.630066 615.205139,323.290771 
	C615.205139,323.290771 615.212891,323.071655 615.560181,323.159790 
	C616.931519,322.824921 617.955566,322.401917 618.979553,321.978943 
	C618.979553,321.978943 618.999634,322.001221 619.294800,321.992432 
	C620.084351,321.684387 620.578735,321.385162 621.073120,321.085938 
	C621.073120,321.085907 621.010498,321.002136 621.345337,321.048401 
	C622.233459,320.822327 622.786743,320.549957 623.340027,320.277588 
	C623.340027,320.277618 623.703064,320.226624 624.237671,320.146057 
	C626.627075,319.467560 628.481934,318.869659 630.336792,318.271759 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M488.227081,506.851227 
	C477.459076,496.972076 466.283844,496.545502 455.611816,505.908691 
	C454.060059,505.847137 452.792542,505.633331 451.345276,505.085205 
	C452.585205,504.062775 454.249756,503.650665 455.386200,502.643036 
	C464.930969,494.180450 477.399109,493.946198 486.998993,502.435822 
	C488.905823,504.122101 490.276154,504.958710 492.594116,502.929077 
	C491.457489,504.138123 490.010986,505.431488 488.227081,506.851227 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M657.111938,309.744568 
	C656.588867,309.588684 656.065796,309.432770 654.823181,309.172729 
	C645.264648,309.256989 636.425720,309.445374 627.586731,309.633789 
	C626.930542,309.665222 626.274414,309.696625 624.885132,309.785522 
	C621.391602,310.474335 618.631165,311.105682 615.870728,311.737061 
	C615.870728,311.737061 615.816711,311.788818 615.431274,311.755341 
	C613.793091,311.918243 612.540283,312.114624 611.194214,312.210449 
	C611.100952,312.109894 610.914062,311.908905 610.914062,311.908905 
	C614.689270,310.621704 618.379822,308.884583 622.259888,308.155273 
	C627.757080,307.121948 633.381897,306.605194 638.975525,306.307709 
	C642.399170,306.125641 645.876892,306.674652 649.312317,307.057373 
	C658.003296,308.025665 666.691040,309.033203 675.358704,310.185974 
	C676.272034,310.307404 677.042969,311.499939 677.550232,312.597412 
	C673.791992,312.384369 670.363403,311.769470 666.934875,311.154602 
	C666.529602,311.087067 666.124390,311.019531 665.098694,310.693481 
	C662.022827,310.204865 659.567383,309.974731 657.111938,309.744568 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M617.719299,377.078796 
	C612.266602,388.605865 606.468262,400.166840 600.388367,411.916687 
	C602.314697,406.672668 604.402100,401.186371 606.774658,395.826324 
	C608.247620,392.498749 610.081421,389.321899 611.884399,386.152008 
	C613.626587,383.089081 615.537048,380.121857 617.719299,377.078796 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M642.479126,400.999573 
	C642.504456,406.093384 642.529846,411.187195 642.383301,416.648743 
	C641.774109,417.044830 641.336853,417.073212 640.571777,417.114685 
	C640.244019,412.356781 640.244019,407.585754 640.244019,402.202209 
	C635.826111,401.964691 631.620422,401.738586 626.414734,401.458740 
	C627.650330,399.272156 628.485657,397.793854 629.683289,396.178894 
	C630.045593,396.042236 630.218384,396.205048 630.141602,396.554871 
	C629.942627,397.839996 629.820374,398.775330 629.719482,399.547333 
	C634.129456,400.049255 638.304321,400.524414 642.479126,400.999573 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M593.971252,456.628906 
	C598.505676,446.445435 603.405701,436.358276 608.589478,426.077454 
	C606.005249,432.699951 603.281799,439.582092 600.211182,446.305695 
	C598.560791,449.919403 596.314392,453.260895 593.971252,456.628906 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M666.873352,311.517395 
	C670.363403,311.769470 673.791992,312.384369 677.624512,312.977051 
	C678.028442,312.954865 678.407654,313.092865 678.607422,313.116882 
	C679.319458,313.540558 679.831787,313.940216 680.691772,314.611023 
	C673.841553,314.611023 667.328247,314.611023 660.360718,314.462891 
	C662.208374,313.503204 664.510071,312.691681 666.873352,311.517395 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M576.252930,466.095154 
	C579.123291,467.859192 581.901184,470.017975 584.961670,472.396271 
	C585.942322,470.768646 586.672974,469.555878 587.680542,468.150024 
	C587.727844,470.249756 589.004822,473.308472 585.213745,473.862244 
	C582.524719,474.255005 576.877563,469.549255 576.252930,466.095154 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M572.925903,336.969574 
	C572.619202,337.099548 572.380249,337.307343 571.908142,337.841064 
	C571.607300,338.089142 571.214539,338.210236 571.134094,338.134338 
	C571.053650,338.058472 570.889343,337.910370 570.889343,337.910370 
	C573.221558,332.132324 577.395813,328.239197 583.549988,325.952148 
	C583.966919,325.958832 584.086365,326.089691 584.032227,326.300201 
	C583.978149,326.510712 583.908447,326.939789 583.908447,326.939789 
	C583.908447,326.939789 583.975159,327.000671 583.712891,327.029846 
	C583.246948,327.321472 583.043274,327.583954 582.839600,327.846405 
	C582.839600,327.846405 583.013611,327.962341 582.697144,327.973206 
	C582.071167,328.148834 581.875305,328.401733 581.793152,328.742737 
	C581.793152,328.742737 581.826660,328.902344 581.560547,328.896118 
	C581.001038,329.045288 580.820618,329.285522 580.753235,329.610565 
	C580.753235,329.610565 580.714050,329.670929 580.406494,329.736328 
	C579.719910,330.191010 579.341003,330.580261 578.962036,330.969513 
	C578.962036,330.969513 578.997437,331.002014 578.741333,331.057983 
	C578.294067,331.388824 578.102844,331.663666 577.911621,331.938477 
	C577.618591,332.061615 577.374939,332.248932 576.822876,332.796844 
	C576.288391,333.381805 576.111633,333.670380 575.934875,333.958923 
	C575.934875,333.958923 575.997559,333.997559 575.735352,334.053284 
	C575.293823,334.392242 575.114502,334.675415 574.935181,334.958588 
	C574.935181,334.958588 574.997070,334.997620 574.734985,335.053772 
	C574.295227,335.394165 574.117615,335.678436 573.939941,335.962677 
	C573.939941,335.962677 573.997559,335.997864 573.734863,336.056335 
	C573.290039,336.399719 573.107971,336.684631 572.925903,336.969574 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M617.335327,417.475647 
	C624.374573,417.154663 631.739990,417.084381 639.571045,417.089935 
	C632.578247,417.352661 625.119934,417.539490 617.335327,417.475647 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M465.278961,505.793213 
	C469.000519,503.118103 473.284821,503.553406 477.850189,505.614136 
	C473.978363,505.901306 469.865112,505.893646 465.278961,505.793213 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M609.177124,425.798279 
	C610.683472,422.964844 612.514160,420.018646 614.698120,417.154175 
	C613.201416,420.052429 611.351379,422.868958 609.177124,425.798279 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M588.312500,467.936096 
	C589.641479,464.367462 591.332214,460.771790 593.273560,456.965454 
	C591.907532,460.472931 590.290894,464.190979 588.312500,467.936096 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M580.295898,352.941223 
	C581.755066,350.390045 583.567566,347.821167 585.695557,345.139648 
	C584.223755,347.659210 582.436462,350.291382 580.295898,352.941223 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M593.335938,337.979675 
	C593.245789,337.543579 593.537964,337.123169 594.264771,336.406342 
	C595.114502,335.728607 595.529602,335.347351 595.944641,334.966064 
	C596.266174,334.886414 596.538940,334.724640 597.143433,334.205017 
	C597.728577,333.662811 597.933228,333.396362 598.137878,333.129944 
	C598.137878,333.129944 598.001099,333.060516 598.334717,333.075226 
	C599.020874,332.967926 599.373352,332.845917 599.813782,332.810791 
	C599.901733,332.897705 600.080017,333.068939 600.080017,333.068939 
	C597.959412,334.711090 595.838745,336.353241 593.335938,337.979675 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M571.014221,460.339355 
	C572.709778,461.665253 574.390991,463.360626 576.185730,465.355438 
	C574.532837,464.006195 572.766296,462.357513 571.014221,460.339355 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M567.050964,455.309326 
	C568.335449,456.399261 569.593750,457.870361 570.941284,459.656647 
	C569.695251,458.544708 568.359985,457.117615 567.050964,455.309326 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M599.756470,412.123108 
	C599.284546,413.949371 598.445618,415.805267 597.333313,417.859344 
	C597.836487,416.089264 598.612976,414.121002 599.756470,412.123108 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M613.064514,325.149872 
	C613.179932,328.340179 610.851379,327.932068 608.365967,327.942780 
	C607.878845,327.875336 607.689453,327.680603 607.765381,327.503357 
	C607.841248,327.326111 607.940308,326.953461 607.940308,326.953461 
	C607.940308,326.953461 607.975525,327.005646 608.269104,326.974121 
	C609.036438,326.624115 609.510193,326.305573 609.984009,325.987061 
	C609.984009,325.987091 609.999878,326.001221 610.246948,325.973846 
	C610.728577,325.722015 610.963257,325.497589 611.197876,325.273163 
	C611.197876,325.273163 611.240417,325.173462 611.537354,325.208801 
	C612.244385,325.212708 612.654419,325.181305 613.064514,325.149872 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M589.260864,341.905090 
	C590.036865,340.638672 591.196472,339.401947 592.659790,338.059937 
	C591.857239,339.281372 590.750854,340.608124 589.260864,341.905090 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M607.973328,327.972839 
	C606.564636,329.025604 605.061462,329.980896 603.324097,330.666565 
	C603.443481,329.993530 603.796997,329.590149 604.150513,329.186768 
	C604.150513,329.186798 604.045837,328.991608 604.379883,329.048462 
	C605.162659,328.755920 605.611389,328.406494 606.060120,328.057068 
	C606.060120,328.057068 606.006165,328.000488 606.305298,328.012848 
	C606.966125,327.910339 607.327759,327.795471 607.689453,327.680603 
	C607.689453,327.680603 607.878845,327.875336 607.973328,327.972839 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M604.894287,313.887482 
	C606.637329,313.250000 608.380310,312.612518 610.518677,311.941956 
	C610.914062,311.908905 611.100952,312.109894 611.104736,312.374725 
	C611.108521,312.639557 610.989502,312.994995 610.989502,312.994995 
	C610.989502,312.994995 611.000000,313.001221 610.667480,312.975983 
	C609.550171,313.285767 608.765320,313.620819 607.980530,313.955872 
	C607.980530,313.955872 607.988525,313.998108 607.636230,313.973450 
	C606.615112,314.064423 605.946289,314.180054 605.181641,314.193726 
	C605.085754,314.091766 604.894287,313.887482 604.894287,313.887482 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M592.548218,320.787964 
	C591.456360,321.839355 590.080505,322.912109 588.379150,323.752441 
	C588.053589,323.520020 588.140991,323.077820 588.140991,323.077820 
	C588.637451,322.833984 589.133972,322.590118 590.059326,322.087891 
	C590.712524,321.584503 590.936768,321.339478 591.161011,321.094452 
	C591.528748,320.999390 591.896484,320.904327 592.548218,320.787964 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M603.564026,315.654816 
	C603.564026,315.654816 603.243652,315.691681 602.789551,315.795227 
	C601.912109,315.956635 601.488708,316.014526 601.004333,316.005157 
	C600.943298,315.937897 600.823669,315.801208 600.823669,315.801208 
	C601.945007,315.190125 603.066406,314.579041 604.541016,313.927734 
	C604.894287,313.887482 605.085754,314.091766 605.099487,314.365356 
	C605.113159,314.638977 605.008911,315.005859 605.008911,315.005859 
	C605.008911,315.005859 605.000000,315.000000 604.728516,315.024170 
	C604.159424,315.250488 603.861694,315.452667 603.564026,315.654816 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M481.224487,508.062897 
	C482.084198,508.295044 482.878876,508.865112 483.825623,509.694366 
	C483.038269,509.435944 482.098877,508.918396 481.224487,508.062897 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M595.936584,317.944824 
	C597.318237,317.270569 598.699890,316.596283 600.452576,315.861603 
	C600.823669,315.801208 600.943298,315.937897 600.930420,316.189453 
	C600.917603,316.441040 600.837097,316.830139 600.837097,316.830139 
	C600.837097,316.830139 600.878296,316.957581 600.537842,316.912781 
	C598.908569,317.358185 597.619751,317.848419 596.232788,318.239655 
	C596.134766,318.140656 595.936584,317.944824 595.936584,317.944824 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M462.003113,507.609863 
	C462.481201,506.896271 463.298737,506.321320 464.429016,505.708557 
	C463.942017,506.363342 463.142303,507.055908 462.003113,507.609863 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M478.108887,506.247620 
	C479.027771,506.303986 479.971527,506.728607 481.071045,507.429413 
	C480.195770,507.342346 479.164734,506.979095 478.108887,506.247620 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M568.665649,341.838593 
	C568.388733,342.749481 567.897888,343.698944 567.068054,344.720459 
	C566.819946,344.410858 566.910828,344.029175 567.314697,343.258728 
	C567.879883,342.643311 568.017883,342.360840 568.041687,342.022583 
	C568.041687,342.022583 568.451599,341.877136 568.665649,341.838593 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M603.813110,329.156860 
	C603.796997,329.590149 603.443481,329.993530 602.950439,330.630554 
	C602.443115,330.960876 602.075317,331.057495 601.422852,331.172119 
	C601.917419,330.502380 602.696594,329.814697 603.813110,329.156860 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M587.823181,323.073059 
	C588.140991,323.077820 588.053589,323.520020 588.005371,323.740051 
	C587.957092,323.960114 587.987305,323.977417 587.715759,324.021179 
	C587.200684,324.318054 586.957153,324.571136 586.713562,324.824219 
	C586.713562,324.824188 586.298401,324.847565 586.097412,324.794006 
	C586.432800,324.183075 586.969055,323.625702 587.823181,323.073059 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M613.232178,325.051697 
	C612.654419,325.181305 612.244385,325.212708 611.526184,325.265137 
	C611.917114,324.855042 612.616089,324.423950 613.656616,324.007324 
	C613.998169,324.021790 613.884033,324.435577 613.829224,324.642944 
	C613.774353,324.850311 613.399780,324.953552 613.232178,325.051697 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M460.285553,508.982483 
	C460.317841,508.580597 460.670929,508.159729 461.293091,507.657532 
	C461.243591,508.038635 460.924988,508.501068 460.285553,508.982483 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M567.797607,342.023010 
	C568.017883,342.360840 567.879883,342.643311 567.413086,342.979095 
	C567.316772,342.733307 567.435120,342.378387 567.797607,342.023010 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M596.042236,319.061005 
	C595.589050,319.383850 595.135803,319.706696 594.446533,319.821930 
	C594.210510,319.614349 594.321716,319.176117 594.321716,319.176117 
	C594.321716,319.176117 594.664368,319.087799 595.084229,319.036011 
	C595.683411,319.009827 595.862793,319.035400 596.042236,319.061005 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M569.920776,339.794983 
	C570.108948,339.290771 570.297180,338.786560 570.687378,338.096375 
	C570.889343,337.910370 571.053650,338.058472 571.053284,338.400574 
	C570.961304,339.100616 570.869629,339.458527 570.777954,339.816467 
	C570.778015,339.816467 570.946594,339.860901 570.654663,339.817657 
	C570.362671,339.774414 569.920776,339.794983 569.920776,339.794983 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M486.980072,508.810242 
	C486.912231,508.579590 487.126312,508.299438 487.594849,507.930725 
	C487.653534,508.148407 487.457764,508.454620 486.980072,508.810242 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M585.797974,325.024414 
	C585.942566,325.393158 585.759155,325.654175 585.267395,325.689880 
	C585.063354,325.537872 585.128052,325.064514 585.128052,325.064514 
	C585.128052,325.064514 585.574402,324.990021 585.797974,325.024414 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M455.162476,508.161255 
	C455.376312,508.073700 455.669342,508.239288 456.057678,508.645996 
	C455.849213,508.729584 455.545441,508.571991 455.162476,508.161255 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M596.041992,319.042664 
	C595.862793,319.035400 595.683411,319.009827 595.235107,318.943634 
	C595.083435,318.604889 595.272339,318.362579 595.734741,318.060425 
	C595.936584,317.944824 596.134766,318.140656 596.149902,318.411194 
	C596.165100,318.681763 596.064026,319.049896 596.064026,319.049896 
	C596.064026,319.049896 596.041748,319.024323 596.041992,319.042664 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M594.040710,319.195740 
	C594.321716,319.176117 594.210510,319.614349 594.156616,319.833954 
	C594.102661,320.053558 594.044739,320.017792 593.779419,320.020630 
	C593.339966,320.014679 593.165771,320.005890 592.991516,319.997101 
	C593.247559,319.736542 593.503662,319.475952 594.040710,319.195740 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M456.192322,509.101196 
	C456.415161,508.977722 456.726135,509.114258 457.148987,509.484711 
	C456.934052,509.599518 456.607239,509.480347 456.192322,509.101196 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M457.261230,509.886780 
	C457.301910,509.694000 457.673767,509.733765 457.813232,509.857544 
	C457.708649,510.014038 457.464600,510.046814 457.261230,509.886780 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M592.918701,320.192993 
	C593.165771,320.005890 593.339966,320.014679 593.774048,320.054016 
	C593.788940,320.317932 593.543945,320.551300 593.038757,320.792999 
	C592.778564,320.801300 592.845947,320.388855 592.918701,320.192993 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M566.546875,344.781158 
	C566.659058,345.073364 566.562317,345.402161 566.222229,345.612946 
	C565.968811,345.325653 565.958801,345.156342 565.948792,344.987030 
	C565.948792,344.987030 566.337891,344.817749 566.546875,344.781158 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M599.957520,331.915833 
	C600.193359,331.675934 600.429138,331.436066 600.923218,331.179077 
	C601.181519,331.161987 601.117859,331.571350 600.793152,331.762817 
	C600.298096,331.941437 600.127808,331.928619 599.957520,331.915833 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M599.958130,331.943848 
	C600.127808,331.928619 600.298096,331.941437 600.724487,331.958252 
	C600.918274,332.300140 600.753601,332.577118 600.283203,332.931061 
	C600.080017,333.068939 599.901733,332.897705 599.878845,332.620178 
	C599.855957,332.342682 599.911621,331.943176 599.911621,331.943176 
	C599.911621,331.943176 599.958801,331.971893 599.958130,331.943848 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M569.843262,340.050781 
	C569.920776,339.794983 570.362671,339.774414 570.583252,339.767670 
	C570.620728,340.083069 570.437744,340.405212 569.969360,340.781067 
	C569.711304,340.658722 569.738525,340.482635 569.843262,340.050781 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M588.110840,342.847290 
	C588.029907,342.626404 588.206787,342.326263 588.640869,341.946228 
	C588.721680,342.166901 588.545166,342.467468 588.110840,342.847290 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M586.265991,344.904053 
	C586.176636,344.703461 586.323608,344.400269 586.712036,344.001129 
	C586.802979,344.203918 586.652649,344.502655 586.265991,344.904053 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M565.731323,345.081177 
	C565.958801,345.156342 565.968811,345.325653 565.992249,345.749023 
	C566.005615,346.003052 566.000000,346.000092 565.803833,346.043396 
	C565.607605,346.086670 565.226440,346.220337 565.226440,346.220337 
	C565.226440,346.220337 565.063599,346.057465 564.982910,345.973602 
	C565.047546,345.601349 565.251404,345.363220 565.731323,345.081177 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M590.907837,321.134399 
	C590.936768,321.339478 590.712524,321.584503 590.237305,321.872681 
	C590.209106,321.668640 590.431946,321.421478 590.907837,321.134399 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M584.879272,325.121094 
	C585.128052,325.064514 585.063354,325.537872 585.038818,325.775543 
	C585.014221,326.013214 585.000549,326.002106 584.773560,326.014648 
	C584.546631,326.027222 584.086365,326.089691 584.086365,326.089691 
	C584.086365,326.089691 583.966919,325.958832 583.907837,325.890991 
	C584.109253,325.608032 584.369873,325.392853 584.879272,325.121094 
z"/>
<path fill="#F5BDBA" opacity="1.000000" stroke="none" 
	d="
M443.245056,498.005432 
	C443.490723,497.934479 443.767853,498.143097 444.116455,498.608459 
	C443.884125,498.671814 443.580353,498.478424 443.245056,498.005432 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M628.080322,309.736938 
	C636.425720,309.445374 645.264648,309.256989 654.544922,309.188293 
	C646.182129,309.485352 637.378052,309.662720 628.080322,309.736938 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M652.658752,314.460907 
	C648.972290,315.159912 644.957764,315.650421 640.509949,316.129700 
	C644.161316,315.496460 648.246033,314.874451 652.658752,314.460907 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M616.291687,311.822876 
	C618.631165,311.105682 621.391602,310.474335 624.565308,309.865967 
	C622.223328,310.562195 619.467957,311.235443 616.291687,311.822876 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M657.326416,310.087738 
	C659.567383,309.974731 662.022827,310.204865 664.790161,310.685852 
	C662.581726,310.768127 660.061340,310.599518 657.326416,310.087738 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M629.925903,318.194519 
	C628.481934,318.869659 626.627075,319.467560 624.401367,320.063660 
	C625.858704,319.413666 627.686829,318.765442 629.925903,318.194519 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M596.418579,319.051147 
	C596.064026,319.049896 596.165100,318.681763 596.247986,318.510193 
	C597.619751,317.848419 598.908569,317.358185 600.544861,316.846161 
	C599.519226,317.567047 598.146240,318.309692 596.418579,319.051147 
z"/>
<path fill="#E2BDBA" opacity="1.000000" stroke="none" 
	d="
M629.959290,395.960754 
	C630.350159,394.980377 630.827332,394.081482 631.557739,393.005249 
	C631.811035,392.827942 631.997864,393.000092 632.025757,393.407471 
	C631.441895,394.611572 630.830139,395.408295 630.218384,396.205048 
	C630.218384,396.205048 630.045593,396.042236 629.959290,395.960754 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M639.677979,316.060913 
	C638.642090,316.450562 637.216370,316.788696 635.409973,317.101166 
	C636.448914,316.720093 637.868469,316.364716 639.677979,316.060913 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M611.380371,313.021912 
	C610.989502,312.994995 611.108521,312.639557 611.197998,312.475281 
	C612.540283,312.114624 613.793091,311.918243 615.425842,311.718384 
	C614.460999,312.159515 613.116089,312.604156 611.380371,313.021912 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M634.687622,317.018250 
	C633.955383,317.404388 632.852234,317.746674 631.390381,318.079651 
	C632.126587,317.705017 633.221680,317.339722 634.687622,317.018250 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M618.612183,321.965881 
	C617.955566,322.401917 616.931519,322.824921 615.565979,323.271729 
	C616.231262,322.848022 617.238037,322.400452 618.612183,321.965881 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M608.318848,313.984955 
	C608.765320,313.620819 609.550171,313.285767 610.667358,312.971130 
	C610.218872,313.332367 609.438049,313.673187 608.318848,313.984955 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M605.346680,315.024231 
	C605.008911,315.005859 605.113159,314.638977 605.195312,314.467346 
	C605.946289,314.180054 606.615112,314.064423 607.623413,313.955261 
	C607.203491,314.322021 606.443970,314.682343 605.346680,315.024231 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M595.635864,334.994202 
	C595.529602,335.347351 595.114502,335.728607 594.420715,336.192871 
	C594.537048,335.858002 594.932068,335.440186 595.635864,334.994202 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M599.615051,331.970581 
	C599.911621,331.943176 599.855957,332.342682 599.790894,332.533295 
	C599.373352,332.845917 599.020874,332.967926 598.391113,333.127258 
	C598.515320,332.775757 598.916931,332.386871 599.615051,331.970581 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M620.766724,321.060242 
	C620.578735,321.385162 620.084351,321.684387 619.288330,321.979706 
	C619.477966,321.662018 619.969177,321.348267 620.766724,321.060242 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M579.272949,330.957428 
	C579.341003,330.580261 579.719910,330.191010 580.370667,329.740295 
	C580.289551,330.101013 579.936646,330.523193 579.272949,330.957428 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M623.006775,320.209198 
	C622.786743,320.549957 622.233459,320.822327 621.364868,321.096924 
	C621.590820,320.779663 622.132202,320.460236 623.006775,320.209198 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M601.160278,316.867249 
	C600.837097,316.830139 600.917603,316.441040 600.991455,316.256714 
	C601.488708,316.014526 601.912109,315.956635 602.656067,315.885773 
	C602.478943,316.216644 601.981201,316.560486 601.160278,316.867249 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M607.765381,327.503357 
	C607.327759,327.795471 606.966125,327.910339 606.320435,328.048828 
	C606.471497,327.707336 606.906494,327.342224 607.640869,326.965271 
	C607.940308,326.953461 607.841248,327.326111 607.765381,327.503357 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M562.038696,352.755249 
	C561.952576,352.319641 562.166077,351.771027 562.627075,351.057648 
	C562.695740,351.476013 562.517029,352.059113 562.038696,352.755249 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M565.150391,346.496094 
	C565.226440,346.220337 565.607605,346.086670 565.805542,346.046478 
	C565.798157,346.552460 565.592896,347.098663 565.108398,347.765076 
	C564.910889,347.514160 564.992615,347.143005 565.150391,346.496094 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M609.686646,325.987061 
	C609.510193,326.305573 609.036438,326.624115 608.267822,326.942719 
	C608.445129,326.624237 608.917175,326.305664 609.686646,325.987061 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M605.748901,328.054138 
	C605.611389,328.406494 605.162659,328.755920 604.424194,329.149109 
	C604.568909,328.812317 605.003235,328.431763 605.748901,328.054138 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M563.132568,350.786438 
	C563.077148,350.383331 563.295166,349.852478 563.764893,349.171936 
	C563.813171,349.567749 563.609619,350.113190 563.132568,350.786438 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M571.067871,339.730652 
	C570.869629,339.458527 570.961304,339.100616 571.133728,338.476471 
	C571.214539,338.210236 571.607300,338.089142 571.810852,338.061188 
	C571.795532,338.570435 571.576599,339.107666 571.067871,339.730652 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M603.851807,315.686249 
	C603.861694,315.452667 604.159424,315.250488 604.730042,315.028198 
	C604.715210,315.244598 604.427429,315.481140 603.851807,315.686249 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M576.178589,333.866516 
	C576.111633,333.670380 576.288391,333.381805 576.706604,332.998169 
	C576.772766,333.193451 576.597534,333.483795 576.178589,333.866516 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M597.880493,333.186829 
	C597.933228,333.396362 597.728577,333.662811 597.274780,334.003448 
	C597.224854,333.799683 597.423950,333.521698 597.880493,333.186829 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M575.177368,334.871155 
	C575.114502,334.675415 575.293823,334.392242 575.715820,334.022156 
	C575.778870,334.218079 575.599243,334.500916 575.177368,334.871155 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M574.181519,335.874084 
	C574.117615,335.678436 574.295227,335.394165 574.715698,335.022705 
	C574.779968,335.218872 574.601501,335.502197 574.181519,335.874084 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M573.169556,336.880127 
	C573.107971,336.684631 573.290039,336.399719 573.717285,336.027466 
	C573.779419,336.223663 573.596313,336.507172 573.169556,336.880127 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M581.015869,329.622589 
	C580.820618,329.285522 581.001038,329.045288 581.517212,328.827057 
	C581.586121,329.054352 581.432312,329.344482 581.015869,329.622589 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M564.247986,348.891296 
	C564.153381,348.708099 564.283264,348.404755 564.635742,347.980896 
	C564.729675,348.163971 564.601074,348.467560 564.247986,348.891296 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M582.057983,328.721069 
	C581.875305,328.401733 582.071167,328.148834 582.614624,327.907928 
	C582.673218,328.121033 582.497986,328.410217 582.057983,328.721069 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M583.096008,327.794495 
	C583.043274,327.583954 583.246948,327.321472 583.697144,326.987518 
	C583.746521,327.191528 583.549438,327.467041 583.096008,327.794495 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M584.032227,326.300201 
	C584.086365,326.089691 584.546631,326.027222 584.778809,326.021240 
	C584.807922,326.278717 584.604858,326.542175 584.155151,326.872711 
	C583.908447,326.939789 583.978149,326.510712 584.032227,326.300201 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M610.941345,325.270233 
	C610.963257,325.497589 610.728577,325.722015 610.242920,325.965057 
	C610.222900,325.744843 610.453857,325.506073 610.941345,325.270233 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M578.155640,331.858856 
	C578.102844,331.663666 578.294067,331.388824 578.731201,331.036438 
	C578.784729,331.232361 578.592224,331.505798 578.155640,331.858856 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M586.987549,324.792450 
	C586.957153,324.571136 587.200684,324.318054 587.707397,324.010681 
	C587.734314,324.224487 587.497925,324.492584 586.987549,324.792450 
z"/>
<path fill="#C65150" opacity="1.000000" stroke="none" 
	d="
M614.950562,323.286591 
	C615.089539,323.630066 614.859863,323.850555 614.271057,323.979065 
	C614.249329,323.764771 614.472656,323.523590 614.950562,323.286591 
z"/>
<path fill="#DEDEDC" opacity="1.000000" stroke="none" 
	d="
M630.141602,396.554871 
	C630.830139,395.408295 631.441895,394.611572 632.118896,393.493774 
	C634.214783,390.629120 636.187378,388.035980 638.299744,385.562134 
	C639.401001,384.272369 640.748352,383.192749 642.339233,382.000763 
	C642.693726,388.015991 642.693604,394.048676 642.586304,400.540466 
	C638.304321,400.524414 634.129456,400.049255 629.719482,399.547333 
	C629.820374,398.775330 629.942627,397.839996 630.141602,396.554871 
z"/>
<path fill="#E1DCD9" opacity="1.000000" stroke="none" 
	d="
M735.079956,571.624634 
	C728.368530,571.752258 722.063477,571.752258 715.346802,571.752258 
	C715.346802,555.867859 715.346802,539.809998 715.346802,523.273315 
	C726.159180,522.899963 737.126648,521.363831 746.206726,529.950562 
	C760.457825,543.427429 754.971985,564.869202 735.079956,571.624634 
z"/>
<path fill="#D8D8D7" opacity="1.000000" stroke="none" 
	d="
M418.587860,544.579346 
	C420.564606,539.979370 422.361786,535.718506 424.487488,530.678894 
	C428.369263,539.992981 431.950867,548.586731 435.746765,557.694641 
	C427.950684,557.694641 420.771912,557.694641 413.155243,557.694641 
	C414.918732,553.405579 416.663513,549.161987 418.587860,544.579346 
z"/>
<path fill="#E2D9D6" opacity="1.000000" stroke="none" 
	d="
M923.014282,572.418945 
	C913.685791,573.719910 907.311157,570.239258 904.690613,562.761963 
	C902.347961,556.077942 905.083496,548.532349 911.131409,544.995605 
	C918.705627,540.566223 928.164917,543.181335 932.184875,550.816040 
	C936.547729,559.101929 932.834656,568.164185 923.014282,572.418945 
z"/>
<path fill="#E2D9D6" opacity="1.000000" stroke="none" 
	d="
M804.952271,543.590942 
	C812.851746,547.408691 816.311157,553.644348 814.859314,560.945435 
	C813.540771,567.575928 807.560913,572.696594 800.795227,572.988525 
	C793.759949,573.292236 787.396423,568.754639 785.527283,561.879456 
	C782.379639,550.301514 792.900391,540.129700 804.952271,543.590942 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
